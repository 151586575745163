import * as React from 'react'
import Layout from "../components/layout/layout";
import {
    bottomOfCover,
    cover,
    coverHidden,
    heartColor,
    maintext,
    mapBox,
    textclass,
    treeColor
} from './index.module.scss'
import {Trans, useTranslation} from "react-i18next";
import {graphql} from "gatsby";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart, faTree} from "@fortawesome/free-solid-svg-icons";
import '@stefanthecode/react-modern-calendar-datepicker/lib/DatePicker.css';
import MapLocation from "../components/maplocation/maplocation";
import Images from "../components/images/images";


const IndexPage = () => {
    const {t} = useTranslation();
    return (
        <Layout pageTitle={t('pages.index.title')} pageDescription={t('pages.index.description')}
                pageKeywords={t('pages.index.keywords')}>
            <div className={cover}/>
            <div className={coverHidden}/>
            <div className="container d-flex align-items-center justify-content-center">
                <div className={[textclass, maintext, bottomOfCover].join(' ')}>
                    <Trans>pages.index.welcome1</Trans>&nbsp;Bolero&nbsp;
                    <Trans>pages.index.welcome2</Trans>&nbsp;
                    <FontAwesomeIcon className={treeColor} icon={faTree}/>&nbsp;
                    <Trans>pages.index.welcome3</Trans>&nbsp;
                    <FontAwesomeIcon className={heartColor} icon={faHeart}/>&nbsp;
                    <Trans>pages.index.welcome4</Trans>
                </div>
            </div>
            <div className="text-center">
                <a target="_blank" rel="noreferrer" href="https://youtu.be/XXEV7YQYueM">
                    <div className="btn btn-reservation orange watchVideo"><Trans>menu.watchvideo</Trans></div>
                </a>
            </div>

            <Images/>

            <div className="text-center textapartment">
                <Trans>menu.location</Trans>
            </div>
            <div className={mapBox}>
                <MapLocation height={"500px"}/>
            </div>
        </Layout>
    )
}
export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
