import "flag-icons/css/flag-icons.css"
import React from "react";
import {I18nextContext} from "gatsby-plugin-react-i18next";

const MapLocation = props => {
    const {width, height} = props;
    const context = React.useContext(I18nextContext);

    const language = context.language === "rs" ? "sr" : "en";


    return (
        <iframe
            title="Location"
            width={width || '100%'}
            height={height || '700px'}
            src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8394.874891682157!2d19.70497367200672!3d43.720062023372186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47582f9929356183%3A0x315891c0dd5fc879!2sApartman%20Bolero%20Zlatibor!5e0!3m2!1s"+language+"!2s"+language+"!4v1670081721538!5m2!1s"+language+"!2s" + language}/>
    );
};

export default MapLocation;
