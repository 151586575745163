import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "react-i18next";
import {faTree} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    treeColor
} from '../../pages/index.module.scss'

const Images = props => {
    return (
        <div>
            <div className="row">
                <div className="col-md-3">
                    <div className="text-center">
                        <StaticImage style={{height: "50vh"}} src='../../images/pro/livingroom1.jpg'
                                     alt='Living room overall'/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="text-center">
                        <StaticImage style={{height: "50vh"}} src='../../images/pro/livingroom2.jpg'
                                     alt='Living room with terrace'/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="text-center">
                        <StaticImage style={{height: "50vh"}} src='../../images/pro/diningroom.jpg' alt='Dining room'/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="text-center">
                        <StaticImage style={{height: "50vh"}} src='../../images/pro/livingroom3.jpg'
                                     alt='Living room general'/>
                    </div>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-md-3">
                    <div className="text-center">
                        <StaticImage style={{height: "50vh"}} src='../../images/pro/fireplace.jpg' alt='Fireplace'/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="text-center">
                        <StaticImage style={{height: "50vh"}} src='../../images/pro/bedroom.jpg' alt='Bedroom overall'/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="text-center">
                        <StaticImage style={{height: "50vh"}} src='../../images/pro/bedroom2.jpg' alt='Bedroom'/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="text-center">
                        <StaticImage style={{height: "50vh"}} src='../../images/pro/bathroom.jpg' alt='Bathroom'/>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                    <div className="text-center">
                        <div className="textapartment">
                            <Trans>pages.index.viewfromapartment</Trans> <FontAwesomeIcon className={treeColor}
                                                                                          icon={faTree}/>
                        </div>
                        <StaticImage style={{height: "50vh"}} src='../../images/pro/view1.jpg' alt='view'/>
                    </div>
                </div>
                <div className="col-md-1"></div>
            </div>
        </div>
    );
};

export default Images;
